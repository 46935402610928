import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import ContactWithValidation from '../contact/contactform.js';

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
    marginLeft: '10%',
    marginRight: '10%',
  },
  cardStyle: {
    padding: '2%',
    backgroundColor: '#EEF2F6',
  },
  content: {
    backgroundColor: '#134056',
    color: '#ffffff',
  },
  dividerStyle: { background: '#134056' },
});

export default function SpecAndContact() {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{
        margin: '0 auto',
        padding: '0',
        overflowX: 'hidden',
        backgroundColor: '#EEF2F6',
        color: '#134056',
      }}
    >
      <Grid
        container
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          justifyContent: 'space-evenly',
          marginTop: '5%',
          marginLeft: '10%',
          width: '82%',
        }}
      >
        <Grid item xs={10} md={8} lg={5}>
          <Card
            className={classes.cardStyle}
            style={{
              marginBottom: '10%',
              padding: '3%',
              backgroundColor: '#EEF2F6',
              borderStyle: 'solid',
              borderWidth: '2px',
              borderColor: '#103049',
            }}
          >
            <Typography
              variant='h4'
              style={{
                paddingTop: '1%',
                paddingBottom: '1%',
                color: '#103049',
              }}
            >
              Remote Recording: <br /> Studio Specs
            </Typography>
            <Card
              className={classes.cardStyle}
              style={{
                backgroundColor: '#3A6C84',
                color: '#ffffff',
                marginTop: '4%',
                marginBottom: '4%',
              }}
            >
              <Typography variant='body1' style={{ padding: '1%' }}>
                Microphones: Neumann TLM103, Audio-Technica AT2035
              </Typography>
              <Typography variant='body1' style={{ padding: '1%' }}>
                Interfaces: Solid State Logic SSL2, Focusrite Scarlett Solo
              </Typography>
              <Typography variant='body1' style={{ padding: '1%' }}>
                Sound Treated Recording Space
              </Typography>
            </Card>
          </Card>
        </Grid>
        <Grid
          item
          xs={10}
          md={8}
          lg={5}
          style={{
            marginBottom: '5%',
            borderStyle: 'solid',
            borderWidth: '2px',
            borderColor: '#103049',
            borderRadius: '8px',
          }}
        >
          <ContactWithValidation />
        </Grid>
      </Grid>
    </div>
  );
}
