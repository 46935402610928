import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import CoverImage from '../../assets/images/cover.jpg';
import { Demo } from '../demo/demo';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
  },
  coverStyle: {
    color: '#ffffff',
    width: '100%',
  },
  dividerStyle: { background: '#ffffff' },
});

export default function Cover() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <Card>
          <div style={{ width: '100%', position: 'relative' }}>
            <div
              className={classes.coverStyle}
              style={{
                position: 'absolute',
              }}
            >
              <Typography
                variant='h2'
                component='h1'
                style={{
                  padding: '2%',
                  textShadow: '5px 5px #380C00',
                  marginLeft: '10%',
                  marginTop: '100px',
                }}
              >
                <st>Sean Gancayco</st>
              </Typography>
              <Typography
                variant='h6'
                style={{
                  marginLeft: '12%',
                  marginBottom: '10px',
                  textShadow: '4px 3px #380C00',
                }}
              >
                Character Demo
              </Typography>
              <div style={{ marginLeft: '12%', marginTop: '1%' }}>
                <Demo />
              </div>
            </div>
            <CardMedia
              component='img'
              alt='Sean Gancayco'
              height='450em'
              src={CoverImage}
            />
          </div>
        </Card>
      </main>
    </div>
  );
}
