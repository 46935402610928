import Cover from './components/cover/cover';
import AboutSection from './components/about/aboutGrid';
import PortfolioSection from './components/portfolio/portfolio';
import { Footer } from './components/footer/footer';
import { initializeApp } from 'firebase/app';
import SpecAndContact from './components/specAndContact/specAndContact';

const firebaseConfig = {
  apiKey: 'AIzaSyAdCCFxbVr3ih4Pxg83bkBQE4ZKJRw-5SU',
  authDomain: 'sean-gancayco.firebaseapp.com',
  projectId: 'sean-gancayco',
  storageBucket: 'sean-gancayco.appspot.com',
  messagingSenderId: '655065113855',
  appId: '1:655065113855:web:eeb81d33137f152f9858ca',
  measurementId: 'G-6H7D66YJK7',
};

const app = initializeApp(firebaseConfig);

function App() {
  return (
    <app>
      <div>
        <Cover />
        <AboutSection />
        <PortfolioSection />
        <SpecAndContact />
        <Footer />
      </div>
    </app>
  );
}

export default App;
