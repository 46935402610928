import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import ProfilePic from '../../assets/images/Sean08.jpg';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
    marginLeft: '10%',
    marginRight: '10%',
  },
  content: {
    backgroundColor: '#3A6C84',
    color: '#ffffff',
  },
  dividerStyle: { background: '#ffffff' },
});

export default function AboutSection() {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{
        margin: '0 auto',
        padding: '0',
        overflowX: 'hidden',
        backgroundColor: '#3A6C84',
        color: '#ffffff',
      }}
    >
      <Grid container>
        <Grid item xs={11} md={8} lg={8}>
          <Typography
            variant='h4'
            component='h4'
            style={{ marginTop: '5%', marginLeft: '20%' }}
          >
            About Me
          </Typography>
          <Divider
            className={classes.dividerStyle}
            style={{
              marginTop: '1%',
              marginLeft: '20%',
              marginRight: '-25%',
              marginBottom: '3%',
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          justifyContent: 'center',
        }}
      >
        <Grid item xs={7} md={3} lg={3}>
          <Card style={{ maxWidth: '300px', marginBottom: '10%' }}>
            <CardMedia
              component='img'
              alt='Sean Gancayco'
              src={ProfilePic}
              style={{
                height: '100%',
              }}
            />
          </Card>
        </Grid>
        <Grid item xs={7} md={5} lg={5}>
          <Typography variant='body1' style={{ marginBottom: '10%' }}>
            I’m a Filipino actor from San Jose, California. I’ve always loved
            animation, video games, and movies, and I’ve always wanted to find a
            way to help create stories that move people. I originally studied
            computer science to become a game developer, but after performing in
            theater I realized that I could be the instrument which connects
            people to stories.
            <br />
            <br />
            My goal as an actor is to inspire people the same way that fiction
            inspired me. I want the people who experience the stories I tell to
            feel understood and spoken to. I wish to better understand the
            nature of what it means to be a human being (and in some instances
            just a being!) and how we connect and empathize with fiction.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
