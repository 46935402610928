import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
    marginLeft: '10%',
    marginRight: '10%',
  },
  cardStyle: {
    padding: '2%',
    backgroundColor: '#EEF2F6',
  },
  content: {
    backgroundColor: '#134056',
    color: '#ffffff',
  },
  dividerStyle: { background: '#ffffff' },
});

export default function PortfolioSection() {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{
        margin: '0 auto',
        padding: '0',
        overflowX: 'hidden',
        backgroundColor: '#134056',
        color: '#ffffff',
      }}
    >
      <Grid container>
        <Grid item xs={11} md={8} lg={8}>
          <Typography
            variant='h4'
            component='h4'
            style={{ marginTop: '5%', marginLeft: '20%' }}
          >
            Portfolio
          </Typography>
          <Divider
            className={classes.dividerStyle}
            style={{
              marginTop: '1%',
              marginLeft: '20%',
              marginRight: '-25%',
              marginBottom: '3%',
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          justifyContent: 'space-evenly',
          width: '70%',
          marginLeft: '14%',
        }}
      >
        <Grid item xs={9} md={7} lg={4}>
          <Card
            className={classes.cardStyle}
            style={{
              marginBottom: '10%',
              padding: '3%',
              backgroundColor: '#EEF2F6',
            }}
          >
            <Typography
              variant='h5'
              style={{ paddingTop: '1%', paddingBottom: '1%' }}
            >
              Voice-Over Training
            </Typography>
            <Card
              className={classes.cardStyle}
              style={{
                backgroundColor: '#3C8264',
                color: '#ffffff',
                marginBottom: '1%',
              }}
            >
              <Typography variant='h6'>Animation/Interactive</Typography>
              <Typography variant='body1'>
                Tony Oliver, Julie Maddalena, Kal-El Bogdanove, Richard Horvitz,
                Ivy Isenberg, Richard Tatum, Mami Okada, Mary Elizabeth McGlynn,
                Keythe Farley, Amber Lee Connors, Brittany Lauda
              </Typography>
            </Card>
            <Card
              className={classes.cardStyle}
              style={{
                backgroundColor: '#3F4A99',
                color: '#ffffff',
                marginBottom: '1%',
              }}
            >
              <Typography variant='h6'>ADR</Typography>
              <Typography variant='body1'>Tony Oliver</Typography>
            </Card>
            <Card
              className={classes.cardStyle}
              style={{
                backgroundColor: '#23759F',
                color: '#ffffff',
                marginBottom: '1%',
              }}
            >
              <Typography variant='h6'>Private Coaching</Typography>
              <Typography variant='body1'>
                Dorah Fine, Richard Horvitz, Richard Tatum
              </Typography>
            </Card>
            <Card
              className={classes.cardStyle}
              style={{
                backgroundColor: '#3F9799',
                color: '#ffffff',
                marginBottom: '1%',
              }}
            >
              <Typography variant='h6'>Commercial</Typography>
              <Typography variant='body1'>Emily Latka</Typography>
            </Card>
          </Card>
        </Grid>
        <Grid item xs={10} md={8} lg={4}>
          <Card
            className={classes.cardStyle}
            style={{
              marginLeft: '5%',
              maxWidth: '600px',
              marginBottom: '10%',
              padding: '3%',
            }}
          >
            <Typography
              variant='h5'
              style={{ paddingTop: '1%', paddingBottom: '1%' }}
            >
              Theatre
            </Typography>
            <Card
              className={classes.cardStyle}
              style={{
                backgroundColor: '#6069AF',
                color: '#ffffff',
                padding: '4%',
                marginBottom: '1%',
              }}
            >
              <Typography variant='body1'>
                Ali / Anon(ymous) / West Valley College
              </Typography>
            </Card>
            <Card
              className={classes.cardStyle}
              style={{
                backgroundColor: '#3C8264',
                color: '#ffffff',
                padding: '4%',
                marginBottom: '1%',
              }}
            >
              <Typography variant='body1'>
                Marius Pontmercy / Les Miserables / Evergreen Studio of Music &
                The Arts
              </Typography>
            </Card>
            <Card
              className={classes.cardStyle}
              style={{
                backgroundColor: '#3F4A99',
                color: '#ffffff',
                padding: '4%',
                marginBottom: '1%',
              }}
            >
              <Typography variant='body1'>
                The Drowsy Chaperone / Man in the Chair / Evergeen Valley High
                School
              </Typography>
            </Card>
            <Card
              className={classes.cardStyle}
              style={{
                backgroundColor: '#23759F',
                color: '#ffffff',
                padding: '4%',
                marginBottom: '1%',
              }}
            >
              <Typography variant='body1'>
                Rumors / Glenn Cooper / Evergreen Valley High School
              </Typography>
            </Card>
            <Card
              className={classes.cardStyle}
              style={{
                backgroundColor: '#3C8275',
                color: '#ffffff',
                padding: '4%',
                marginBottom: '1%',
              }}
            >
              <Typography variant='body1'>
                Eurydice / Nasty Interesting Man & Lord of the Underworld / West
                Valley College
              </Typography>
            </Card>
          </Card>
        </Grid>
        <Grid item xs={10} md={8} lg={4}>
          <Card
            className={classes.cardStyle}
            style={{
              marginLeft: '5%',
              marginRight: '5%',
              maxWidth: '600px',
              marginBottom: '10%',
              padding: '3%',
            }}
          >
            <Typography
              variant='h5'
              style={{ paddingTop: '1%', paddingBottom: '1%' }}
            >
              Animation
            </Typography>
            <Card
              className={classes.cardStyle}
              style={{
                backgroundColor: '#3F6899',
                color: '#ffffff',
                padding: '4%',
                marginBottom: '1%',
              }}
            >
              <Typography variant='body1'>
                Narrator / The Ice Cream Person / CalArts Short Film
              </Typography>
            </Card>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
