import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

export function Footer() {
  return (
    <Grid
      container
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '80px',
        alignItems: 'center',
        backgroundColor: 'rgba(234, 192, 83, 0.75)',
      }}
    >
      <Grid item>
        <Typography variant='caption'>
          Crafted by Willow Luna LLC. All Rights Reserved.
        </Typography>
      </Grid>
    </Grid>
  );
}
