import * as React from 'react';
import { useRef } from 'react';
import { Form } from 'react-final-form';
import { makeRequired, makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import emailjs from 'emailjs-com';

//mui components
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextField } from 'mui-rff';
import { CssBaseline, Paper } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const phoneRegExp =
  '^[+]?1?[s-]?[(.]?[s]?[0-9]{3}[).-]?[s]?[0-9]{3}[-s.]?[0-9]{4}$';

// We define our schema based on the same keys as our form:
const schema = Yup.object().shape({
  fullName: Yup.string().required('Required'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Please enter a valid phone number.')
    .required('Required'),
  email: Yup.string()
    .email('Please enter a valid email address.')
    .required('Required'),
  message: Yup.string().required('Required'),
});

// Run the makeValidate function...
const validate = makeValidate(schema);
const required = makeRequired(schema);

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const formFields = [
  {
    size: 6,
    field: (
      <TextField
        label='Name'
        name='fullName'
        margin='none'
        required={required.fullName}
      />
    ),
  },
  {
    size: 6,
    field: (
      <TextField
        label='Phone'
        name='phone'
        margin='none'
        required={required.phone}
      />
    ),
  },
  {
    size: 12,
    field: (
      <TextField
        type='email'
        label='Email'
        name='email'
        margin='none'
        required={required.email}
      />
    ),
  },
  {
    size: 12,
    field: (
      <TextField
        name='message'
        multiline
        label='Message'
        margin='none'
        required={required.message}
      />
    ),
  },
];

export default function ContactWithValidation() {
  const [open, setOpen] = React.useState(false);

  const handleSnack = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const contactFormEmail = useRef();

  const sendEmail = () => {
    emailjs.sendForm(
      'service_7me4hg8',
      'template_4jh8kwp',
      contactFormEmail.current,
      'user_JPQkjdf7zA1dSCn9fTd0N'
    );
  };

  const onSubmit = async (values) => {
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);
    sendEmail();
    handleSnack();
    setTimeout(function () {
      window.location.reload(false);
    }, 3000);
  };

  return (
    <Paper
      style={{
        backgroundColor: '#EEF2F6',
        padding: '4%',
      }}
    >
      <CssBaseline />
      <Typography
        variant='h3'
        style={{
          paddingTop: '1%',
          paddingBottom: '1%',
          color: '#103049',
          fontSize: '2.45em',
        }}
        align='center'
        component='h1'
        gutterBottom
      >
        Book Sean
      </Typography>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          fullName: '',
          company: '',
          phone: '',
          email: '',
          message: '',
        }}
        validate={validate}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form ref={contactFormEmail} onSubmit={handleSubmit} noValidate>
            <Grid
              container
              display='flex'
              alignItems='flex-start'
              spacing={2}
              justifyContent='center'
              style={{ color: '#134056' }}
            >
              {formFields.map((item, idx) => (
                <Grid item xs={item.size} key={idx}>
                  {item.field}
                </Grid>
              ))}
              <Grid item lg={3} md={4} xs={5} style={{ marginTop: 20 }}>
                <Button
                  type='button'
                  size='large'
                  fullWidth
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '1.2em',
                    paddingTop: '2%',
                    paddingBottom: '2%',
                    borderRadius: '5px',
                  }}
                  variant='contained'
                  color='text'
                  onClick={form.reset}
                  disabled={submitting || pristine}
                >
                  Reset
                </Button>
              </Grid>
              <Grid
                item
                lg={3}
                md={4}
                xs={5}
                style={{ marginTop: 20, marginLeft: '4%' }}
              >
                <Button
                  size='large'
                  fullWidth
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '1.2em',
                    paddingTop: '2%',
                    paddingBottom: '2%',
                    borderRadius: '5px',
                    color: '#EEF2F6',
                    backgroundColor: '#3A6C84',
                  }}
                  variant='contained'
                  type='submit'
                  disabled={submitting}
                >
                  Send
                </Button>
                <Snackbar
                  open={open}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <Alert
                    onClose={handleClose}
                    severity='success'
                    sx={{ width: '100%' }}
                  >
                    Message sent. We'll be in touch!
                  </Alert>
                </Snackbar>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Paper>
  );
}
